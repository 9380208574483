/* eslint no-param-reassign: ["error", { "props": false }] */

const withTimeout = (timeoutInMs, promise) => {
  const timeoutPromise = new Promise((resolve, reject) => {
    const t = setTimeout(() => {
      clearTimeout(t);
      reject(new Error(`Timed out in ${timeoutInMs} ms.`));
    }, timeoutInMs);
  });
  // Returns a race between our timeout and the passed promise
  return Promise.race([promise, timeoutPromise]);
};

const LoadHtmlSubResource = (tag, loadIntoHead) => {
  return new Promise((resolve, reject) => {
    tag.onerror = reject;
    tag.onload = resolve;
    if (loadIntoHead) {
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(tag);
    } else {
      document.body.appendChild(tag);
    }
  });
};

function CreateLinkTag(url) {
  const linkTag = document.createElement('link');
  linkTag.rel = 'stylesheet';
  linkTag.type = 'text/css';
  linkTag.href = url;
  return linkTag;
}

function CreateScriptTag(url) {
  const scriptTag = document.createElement('script');
  scriptTag.src = url;
  return scriptTag;
}

export const loadScript = (url, ms) => withTimeout(ms, LoadHtmlSubResource(CreateScriptTag(url)));
export const loadCss = (url, ms) => withTimeout(ms, LoadHtmlSubResource(CreateLinkTag(url), true));
