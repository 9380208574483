import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const Option = ({ children, selectProps, value, ...props }) => {
  return (
    <components.Option {...props}>
      <span data-test={`${selectProps.name}_${value}`.toLowerCase()}>{children}</span>
    </components.Option>
  );
};
Option.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired
};

export default Option;