import { roles, routeKeys } from "./constants";

export const filterRoutesBasedOnRole = ({ routes, role }) => {
  return routes.filter((route) => {
    if ([roles.finance, roles.developer].includes(role)) {
      return [routeKeys.manageTeam, routeKeys.myProfile].includes(route.key);
    }

    if (role === roles.viewer) {
      return route.key === routeKeys.myProfile;
    }

    return role === roles.admin;
  });
};
