import React, { useContext, useState, useEffect } from 'react';
import GlobalStyle from '../GlobalStyle/GlobalStyle';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { UserInfoContext } from 'og-merchant-portal-react-library';
import Navigation from '../Navigation/Navigation';
import NavigationRoutes from '../Navigation/NavigationRoutes';
import IntlProvider from '../IntlProvider/IntlProvider';
import loadModules from '../../utils/appComposition';
import styled from 'styled-components';
import { getSessionStorage } from '../../utils/sessionHelper';
import SessionTimeoutModal, {
  TIMEOUT_EXPIRATION_IN_MINUTES,
  ACCESS_TOKEN_DURATION_IN_SECONDS,
} from '../common/SessionTimeoutModal';
import Footer from '../Footer/footer';

const StyledPageContainer = styled.div`
  margin-right: 101px;
  transition: margin-left 300ms;
  min-height: calc(100vh - 120px);

  @media (max-width: ${(props) => props.theme.medias.xs.max}px) {
    padding: 0;
    margin: 1.6rem;
    margin-bottom: 2.4rem;
  }

  @media (max-width: ${(props) => props.theme.medias.s.max}px) {
    padding: 0;
    margin: 1.6rem;
    margin-bottom: 2.4rem;
  }

  @media (max-width: ${(props) => props.theme.medias.l.max}px) {
    padding: 0;
    margin: 1.6rem;
    margin-bottom: 2.4rem;
  }

  @media (min-width: ${(props) => props.theme.medias.l.min}px) {
    margin-left: ${({ isDrawerExpanded }) => (isDrawerExpanded ? '228' : '104')}px;
  }

  @media (min-width: ${(props) => props.theme.medias.xl.min}px) {
    margin-left: ${({ isDrawerExpanded }) => (isDrawerExpanded ? '228' : '104')}px;
  }
`;

const getAvailableTranslationsList = () => {
  const Locales = window.appSettings.availableLocales.split(',');
  return Locales.map((locale) => {
    return locale.toLowerCase();
  });
};

const UserManager = () => {
  const user = useContext(UserInfoContext);
  const [modules, setModules] = useState([]);
  const [theme, setTheme] = useState([]);
  const [availableTranslations] = useState(getAvailableTranslationsList);
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(true);
  const [language] = useState(getSessionStorage('locale') || user.profile?.language || 'fr');

  useEffect(() => {
    Promise.all([fetch('/themes/theme.json', { cache: 'no-cache' }).then((response) => response.json())])
      .then(([theme]) => {
        setTheme(theme);
      })
      .catch((error) => {
        console.error('The portal theme could not be loaded. Please retry later.');
        throw new Error(error);
      });
    loadModules(10000, user.profile.language).then((modules) => {
      setModules(modules);
    });
  }, [user]);

  if (user !== null) {
    return (
      <IntlProvider language={language} availableTranslations={availableTranslations}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <>
              <GlobalStyle />
              <SessionTimeoutModal
                accessTokenDuration={ACCESS_TOKEN_DURATION_IN_SECONDS}
                timeoutExpiration={TIMEOUT_EXPIRATION_IN_MINUTES}
              >
                <Navigation setIsDrawerExpanded={setIsDrawerExpanded} isDrawerExpanded={isDrawerExpanded} />
                <StyledPageContainer isDrawerExpanded={isDrawerExpanded}>
                  <NavigationRoutes modules={modules} user={user} />
                </StyledPageContainer>
                <Footer />
              </SessionTimeoutModal>
            </>
          </BrowserRouter>
        </ThemeProvider>
      </IntlProvider>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default UserManager;
