import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { DropDown } from "og-merchant-portal-react-library";
import locales from "./locale";
import I18nContext from "../../IntlProvider/IntlContext";
import Control from "./Control";
import Option from "./Option";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.medias.m.max}px) {
    div[class$="-menu"] {
      left: 0;
    }
  }
`;

const LanguageSelector = () => {
  const theme = useContext(ThemeContext);
  const { availableTranslations, locale, changeLocale } = useContext(I18nContext);

  const [options, setOptions] = React.useState([]);

  const selectedLocale = { value: locale, label: locale };
  const availableLocalesAndTranslations = Object.keys(locales).length !== 0 && availableTranslations.length > 1;

  const onOptionChange = (option) => {
    if (locale !== option.value) {
      changeLocale(option.value);
    }
  };

  React.useEffect(() => {
    setOptions(
      availableTranslations
        .sort(function(a, b) {
          return a.localeCompare(b);
        })
        .map((language) => {
          return {
            value: language,
            label: locales[language],
          };
        })
    );
  }, [availableTranslations]);

  if (availableLocalesAndTranslations) {
    return (
      <StyledContainer>
        <DropDown
          name="global_menu_item_language_selector"
          value={selectedLocale}
          options={options}
          onChange={onOptionChange}
          isSearchable={false}
          data-test="global_menu_item_language_selector"
          styles={{
            menu: () => ({
              zIndex: 3,
            }),
            control: () => ({
              minWidth: "2.4rem",
              "&:hover": {
                cursor: "pointer",
              },
              "> div": {
                display: "flex",
              },
              border: "none",
            }),
            valueContainer: () => ({
              padding: 0,
              minWidth: "2.4rem",
              "& > div": {
                margin: 0,
                minWidth: "2.4rem",
                fontSize: theme.font.sizes.md,
                fontWeight: theme.font.weights.regular,
                color: theme.colors.greyScale.body,
                textTransform: "uppercase",
              },
            }),
            indicatorsContainer: () => ({
              "& > div": {
                padding: 0,
              },
            }),
            option: () => ({
              padding: "0.8rem 1.6rem",
              "&:hover div span": {
                color: theme.colors.primary.regular,
              },
            }),
          }}
          components={{ Option, Control }}
        />
      </StyledContainer>
    );
  }

  return null;
};

export default LanguageSelector;
