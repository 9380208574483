import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router";
import ErrorBoundary from "../common/ErrorBoundary";
import PageNotFound from "../common/PageNotFound";
import getBaseRoutes from "./moduleRoutes";
import { Row } from "og-merchant-portal-react-library";
import { httpClientUtils } from "../../utils/httpClient";
import { filterRoutesBasedOnRole } from "./filterRoutesBasedOnRole";

export const NavigationRoutesComponent = ({
  modules = [],
  baseRoutes = [],
  user,
}) => {
  const createRoutes = (module, user) => {
    try {
      if (!module.default) {
        console.error(
          `No default export found in the module ${module.getName()}`
        );
        return null;
      }

      if (typeof module.default.routes !== "function") {
        console.warn(
          `No navigation routes defined in the module ${module.getName()}. Your components might not be visible`
        );
        return null;
      }

      const baseRoute = baseRoutes.find(
        (route) => route.module === module.getName()
      );
      if (!baseRoute) {
        console.warn(
          `No base navigation route defined for module ${module.getName()}. Please check the json config`
        );
        return null;
      }

      const moduleRoutes = module.default.routes({
        baseRoute: baseRoute.path,
        assetsPath: module.getDeployedPath(),
      });
      if (!moduleRoutes || !modules.length || modules.length === 0) {
        console.warn(
          `No base navigation route defined for module ${module.getName()}.`
        );
        return null;
      }

      const allowedRoutes = filterRoutesBasedOnRole({
        routes: moduleRoutes,
        role: user?.profile?.role?.name,
      });

      return allowedRoutes.map((route) => {
        // TODO may be check that route.path is prefixed by baseRoute.path
        // Or automatically add the prefix here and not pass the baseRoute to routes fct.
        // Micro Apps might have issue to generate <NavLink to="???" />
        return (
          <Route
            key={route.path}
            exact
            path={route.path}
            render={(props) => {
              const Component = route.component;
              return Component ? (
                <ErrorBoundary>
                  <Component {...props} />
                </ErrorBoundary>
              ) : (
                <div data-test="undefined-component" />
              );
            }}
          />
        );
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <Switch>
      {baseRoutes?.length > 0 &&
        modules?.map((module) => createRoutes(module, user))}
      {baseRoutes?.length > 0 && modules?.length > 0 ? (
        <>
          <Route exact path="/">
            <Redirect to={httpClientUtils.getSetting("homepage")} />
          </Route>
          <Route component={PageNotFound} />
        </>
      ) : (
        <Route
          render={() => (
            <Row className="row" justifyContent="center">
              <div>Loading...</div>
            </Row>
          )}
        />
      )}
    </Switch>
  );
};

const NavigationRoutes = ({ modules = [], user }) => {
  const [baseRoutes, setBaseRoutes] = useState([]);

  useEffect(() => {
    let isDisposed = false;
    getBaseRoutes().then((routes) => {
      if (!isDisposed) {
        setBaseRoutes(routes);
      }
    });
    return () => {
      isDisposed = true;
    };
  }, []);

  return (
    <NavigationRoutesComponent
      modules={modules}
      baseRoutes={baseRoutes}
      user={user}
    />
  );
};

NavigationRoutes.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
};
NavigationRoutes.defaultProps = { modules: [] };

NavigationRoutesComponent.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  baseRoutes: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
};
NavigationRoutesComponent.defaultProps = { modules: [], baseRoutes: [] };

export default NavigationRoutes;
