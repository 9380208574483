import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { UserInfoContext } from 'og-merchant-portal-react-library';

export const useGetDataForNavigation = (props) => {
  const user = useContext(UserInfoContext);
  const intl = useIntl()

  function getPrimaryItemsBasedOnUserProfile(userProfile) {
    const result = [];
    if (!userProfile) {
      return result;
    }

    if (userProfile.isManageTeamAvailable) {
      result.push({
        id: 'manageTeam',
        url: '/manage-team',
        labelId: 'manageTeam.title',
        text: intl.formatMessage({ id: `manageteam.management.team`, defaultMessage: "Manage team"}),
        icon: 'team',
        isSelected: props.history.location.pathname.startsWith('/manage-team'),
      });
    }

    if (userProfile.isMyProfileAvailable) {
      result.push({
        id: 'myProfile',
        url: '/my-profile',
        labelId: 'myprofile.title',
        text: intl.formatMessage({ id: `myprofile.title`, defaultMessage: "My profile" }),
        icon: 'user',
        isSelected: props.history.location.pathname === '/my-profile',
      });
    }

    return result;
  }

  const primaryItems = getPrimaryItemsBasedOnUserProfile(user?.profile);

  const items = {
    primary: primaryItems,
  };

  return { items };
};
