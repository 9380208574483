import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'og-merchant-portal-react-library';
import moment from 'moment';
import httpClient from '../../../utils/httpClient';

import SessionTimeoutContent from './SessionTimeoutContent';
import fetchAuthenticationStatus from './fetchAuthenticationStatus';
import { AUTHENTICATED_STATUS_CODE, UNAUTHENTICATED_STATUS_CODE } from './constant';

const SessionTimeout = ({ accessTokenDuration, timeoutExpiration, children }) => {
  const [isLoggedOut, setIsLoggedOut] = React.useState(false);
  const [lastTokenTimeRotation, setTokenTimeRotation] = React.useState(null);

  const logout = React.useCallback(() => {
    httpClient.put(`/auth/logout`, null, 
    (x) => { 
      window.sessionStorage.removeItem('accountId');
    }, 
    (y) => { 
      console.log(`logout failed ${y}`); 
    }); 
    setIsLoggedOut(true);
  }, []);

  const checkAuth = React.useCallback(() => {
    const serverActivity = moment.utc(global.sessionStorage.getItem('serverActivity'));

    if (serverActivity == null) {
      return;
    }

    if (moment.utc().isAfter(serverActivity.clone().add(parseInt(timeoutExpiration), 'minutes'))) {
      logout();
      return;
    }

    if (lastTokenTimeRotation == null) {
      // if rotation is null then we don't need to refresh the tokens
      // set the next rotation to now + time (now 180 seconds / 3 minutes)
      setTokenTimeRotation(moment.utc().add(parseInt(accessTokenDuration), 'seconds').toISOString());
      return;
    }
    // it means that the check is performed before expiring the 3 minutes. Don't do redundant server check
    else if (moment.utc().isBefore(moment.utc(lastTokenTimeRotation))) {
      return;
    }

    fetchAuthenticationStatus()
      .then((status) => {
        if (status === UNAUTHENTICATED_STATUS_CODE) {
          logout();
          console.log('User session expired');
          return;
        }

        if (status === AUTHENTICATED_STATUS_CODE) {
          setTokenTimeRotation(moment.utc().add(parseInt(accessTokenDuration), 'seconds').toISOString());
          return;
        }
      })
      .catch((error) => {
        console.error('Something wrong happened while checking authentication: ', error);
      });
  }, [lastTokenTimeRotation, accessTokenDuration, logout, timeoutExpiration]);

  React.useEffect(() => {
    const intervalId = setInterval(checkAuth, 15 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [checkAuth]);

  return isLoggedOut ? (
    <Modal
      id="session-timeout-modal"
      onClose={logout}
      showCloseButton={false}
      closeOnOutsideClick={false}
      domIdToAppendModal="modal-root"
    >
      <SessionTimeoutContent />
    </Modal>
  ) : (
    children
  );
};

SessionTimeout.propTypes = {
  accessTokenDuration: PropTypes.number.isRequired,
  timeoutExpiration: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

export default SessionTimeout;
