let moduleRoutes = [];

const load = () => fetch(`/module-routes.json`, { cache: "no-cache" }).then((response) => response.json());

const getAll = async () => {
  if (moduleRoutes && moduleRoutes.length === 0) {
    const jsonResult = await load();
    moduleRoutes = jsonResult.routes;
  }
  return moduleRoutes;
};

export default getAll;
