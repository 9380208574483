import { loadScript } from './libLoader';
import { httpClientUtils } from './httpClient';

const loadedModuleDefinitions = [];

const getModulesBaseUrl = () => {
  return httpClientUtils.getSetting('widgetsUrl');
}

const getModuleDefinitions = async () => {
  if (loadedModuleDefinitions && loadedModuleDefinitions.length > 0) {
    return Promise.resolve(loadedModuleDefinitions);
  }
  const url = `${getModulesBaseUrl()}/modules.json`;
  return fetch(url, { cache: 'no-cache' })
    .then(response => {
      return response.json();
    })
    .then(result => {
      return Promise.resolve(result.modules);
    })
    .catch(error =>
      console.error({
        msg: `An error occured when trying to load the micro apps from ${url}`,
        error
      })
    );
}

const loadJsBundle = (definition, timeout) => {
  const url = `${getModulesBaseUrl()}${definition.relativePath}/main.bundle.js?v=${
    definition.version
  }`;
  return loadScript(url, timeout)
    .then(() => {
      // loaded module should be accessible in the global object
      if (!global[definition.module]) {
        console.error(
          `Loading module ${definition.module} failed. Ensure your package name (package.conf)` +
            ` matches module name in the deployed definition file (modules.json)`
        );
      } else if(loadedModuleDefinitions.indexOf(definition) === -1) {
        loadedModuleDefinitions.push(definition);
      }
    })
    .catch(error =>
      console.error(`Loading module ${definition.module} failed: ${JSON.stringify(error)}`)
    );
};

const getAvailableModules = () =>
  loadedModuleDefinitions.map(d => {
    global[d.module].getName = () => d.module;
    global[d.module].getDeployedPath = () => `${getModulesBaseUrl()}${d.relativePath}`;
    return global[d.module];
  });

const loadModules = async timeout => {
  return getModuleDefinitions()
    .then(moduleDefinitions => {
      return Promise.all(moduleDefinitions.map(definition => loadJsBundle(definition, timeout)));
    })
    .then(() => getAvailableModules());
};

export default loadModules;