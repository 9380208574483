import React from 'react';

import './App.css';
import UserProvider from './components/UserProvider/UserProvider';
import UserManager from './components/UserManager/UserManager';
import { KeycloakProvider } from './components/KeycloakProvider/KeycloakProvider';

const App = () => {
  return (
    <KeycloakProvider>
      <UserProvider>
        <UserManager />
      </UserProvider>
    </KeycloakProvider>
  );
};

export default App;
