import React from 'react';

const LogoutLogo = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 50a40.0003 40.0003 0 0 0 40 40 40.0003 40.0003 0 0 0 40-40 40.0003 40.0003 0 0 0-40-40 40.0003 40.0003 0 0 0-40 40Z"
      fill="#E8F4FA"
      stroke="#DAEDF7"
    />
    <path
      opacity=".15"
      d="M36.7549 82.144c0 .2274.3286.4527.9671.6628.6385.2101 1.5743.4011 2.7541.5619 1.1798.1608 2.5803.2884 4.1218.3754 1.5414.0871 3.1935.1319 4.862.1319 1.6684 0 3.3205-.0448 4.862-.1319 1.5414-.087 2.942-.2146 4.1218-.3754 1.1797-.1608 2.1156-.3518 2.7541-.5619.6385-.2101.9671-.4354.9671-.6628 0-.2275-.3286-.4527-.9671-.6628-.6385-.2102-1.5744-.4011-2.7541-.5619-1.1798-.1609-2.5804-.2884-4.1218-.3755-1.5415-.087-3.1936-.1318-4.862-.1318-1.6685 0-3.3206.0448-4.862.1318-1.5415.0871-2.942.2146-4.1218.3755-1.1798.1608-2.1156.3517-2.7541.5619-.6385.2101-.9671.4353-.9671.6628Z"
      fill="#45413C"
    />
    <path d="M24.0234 33.095h50.874v41.848h-50.874V33.095Z" fill="#00B8F0" />
    <path
      d="M64.6084 33.1h-37.3a3.279 3.279 0 0 0-2.3217.961 3.2808 3.2808 0 0 0-.9603 2.322v35.278a3.239 3.239 0 0 0 .428 1.591L64.6084 33.1Z"
      fill="#4ACFFF"
    />
    <path
      d="M24.0234 33.095h50.874v41.848h-50.874V33.095Z"
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.6154 33.1h-44.31a3.2828 3.2828 0 0 0-3.282 3.282v6.565h50.877v-6.57a3.282 3.282 0 0 0-3.285-3.277Z"
      fill="#fff"
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3047 38.0191a1.641 1.641 0 1 0 3.282 0 1.641 1.641 0 0 0-3.282 0Z"
      fill="#FF6242"
      stroke="#FF6242"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.8701 38.0191c0 .4352.1729.8526.4807 1.1603a1.6406 1.6406 0 0 0 2.3207 0 1.641 1.641 0 1 0-2.8014-1.1603Z"
      fill="#FFE500"
      stroke="#FFE500"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.4336 38.0191a1.641 1.641 0 1 0 3.282 0 1.641 1.641 0 0 0-3.282 0Z"
      fill="#6DD627"
      stroke="#6DD627"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.5999 29.265a18.0573 18.0573 0 0 0-5.2414-12.8564 18.0596 18.0596 0 0 0-19.759-3.9494 18.058 18.058 0 0 0-9.7807 9.8538 18.0592 18.0592 0 0 0-1.3359 6.952V33.1h6.567v-3.83a11.492 11.492 0 0 1 22.984 0v3.83h6.566v-3.835Z"
      fill="#DAEDF7"
    />
    <path
      d="M49.5414 11.2061a18.0574 18.0574 0 0 0-18.059 18.058v3.73a18.059 18.059 0 0 1 36.117 0v-3.73a18.0583 18.0583 0 0 0-5.289-12.769 18.0583 18.0583 0 0 0-12.769-5.289Z"
      fill="#fff"
    />
    <path
      d="M67.5999 29.265a18.0573 18.0573 0 0 0-5.2414-12.8564 18.0596 18.0596 0 0 0-19.759-3.9494A18.0591 18.0591 0 0 0 31.4829 29.265V33.1h6.567v-3.83a11.492 11.492 0 0 1 22.984 0v3.83h6.566v-3.835Z"
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m65.0511 58.344-2.961 2.956h-4.44l-1.48-1.48-1.48 1.48-1.48-1.48-1.48 1.48h-3.585a7.4002 7.4002 0 0 1-12.5245 1.7075A7.4 7.4 0 1 1 48.1451 55.38h13.945l2.961 2.964Z"
      fill="#FFE500"
    />
    <path
      d="m65.0511 58.344-2.961 2.956h-4.44l-1.48-1.48-1.48 1.48-1.48-1.48-1.48 1.48h-3.585a7.4002 7.4002 0 0 1-12.5245 1.7075A7.4 7.4 0 1 1 48.1451 55.38h13.945l2.961 2.964Z"
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.1211 58.344a1.5982 1.5982 0 0 0 .9865 1.4763 1.5976 1.5976 0 0 0 2.0879-.8648 1.5982 1.5982 0 0 0-1.4764-2.2095 1.5975 1.5975 0 0 0-1.4764.9864 1.5984 1.5984 0 0 0-.1216.6116Z"
      fill="#656769"
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogoutLogo;
