import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import IntlContext from "./IntlContext";

const IntlProvider = ({ language, children, availableTranslations }) => {
  const [translations, setTranslations] = useState(null);
  const [locale, setLocale] = useState(language);

  useEffect(() => {
    if (locale != null) {
      const url = `/assets/translations/${locale.toLowerCase()}.json`;
      fetch(url, { cache: "no-cache" })
        .then((response) => response.json())
        .then((data) => setTranslations(data))
        .catch((error) => {
          console.log(`Impossible to fetch the translations from : ${url}`);
          console.log(error);
        });
    }
  }, [locale]);

  function changeLocale(newLocale) {
    global.sessionStorage.setItem("locale", newLocale);
    setLocale(newLocale);
  }

  return (
    translations && (
      <IntlContext.Provider
        value={{
          locale,
          changeLocale,
          availableTranslations
        }}
      >
        <ReactIntlProvider locale={locale} key={locale} messages={translations}>
          {children}
        </ReactIntlProvider>
      </IntlContext.Provider>
    )
  );
};

IntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
};

export default IntlProvider;
