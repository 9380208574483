import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import {
  StyledFooterWrapper,
  StyledFooterItem,
  StyledFooterItemContainer,
  StyledFooterItemSeparator,
  StyledFooterItemWrapper,
} from './Footer.styled';
import { useGetDataFromQuickLinks } from '../../utils/useGetDataFromQuickLinks';

const Footer = (props) => {
  const { isLoading, footerItems } = useGetDataFromQuickLinks({ component: 'FooterLinks' });
  const theme = useContext(ThemeContext);

  return isLoading ? null : (
    <StyledFooterWrapper {...props} theme={theme} footerItems={footerItems.length}>
      <StyledFooterItemContainer>
        {footerItems.map((item, i, { length }) => (
          <StyledFooterItemWrapper key={item.id}>
            <StyledFooterItem index={i} href={item.url} target="_blank">
              {item.text}
            </StyledFooterItem>
            {i + 1 === length ? null : <StyledFooterItemSeparator />}
          </StyledFooterItemWrapper>
        ))}
      </StyledFooterItemContainer>
    </StyledFooterWrapper>
  );
};

export default Footer;
