export function getSessionStorage(key) {
    return global.sessionStorage.getItem(key);
}

export function setSessionStorage(key, value) {
    global.sessionStorage.setItem(key, value);
}

export function removeSessionStorage(key) {
    global.sessionStorage.removeItem(key);
}