import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from "react-dom/client";
import moment from 'moment';
import * as styled from 'styled-components';
import NotificationContext from 'og-merchant-portal-react-library/lib/NotificationContext/NotificationContext';
import FetchContext from 'og-merchant-portal-react-library/lib/FetchContext';
import { UserInfoContext } from 'og-merchant-portal-react-library';

import App from './App';

window.IngenicoLib = {
  React,
  ReactDOM,
  moment,
  NotificationContext,
  FetchContext,
  UserInfoContext,
  'styled-components': styled,
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);

 
 
