import Styled from 'styled-components';

export const StyledFooterWrapper = Styled.div`
    background-color: #FAFAFA;
    height: 40px;
    width: 100%;
    position: relative;
    bottom: 0;
    display: ${(props) => (props.footerItems === 0 ? 'none' : 'block')};
`;

export const StyledFooterItemContainer = Styled.div`
    margin-left: 245px;
    display: flex;
`;

export const StyledFooterItemWrapper = Styled.div`
    display: flex;
`;

export const StyledFooterItemSeparator = Styled.div`
    color: ${(props) => props.theme.colors.greyScale.body};
    height:12px;
    width: 1px;
    background-color: ${(props) => props.theme.colors.greyScale.input};
    margin: 14px 0px;
`;

export const StyledFooterItem = Styled.a`
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.017em;
    text-align: left;
    margin: auto 12px;
    text-decoration: none;
    margin-left: ${(props) => (props.index === 0 ? '0px' : '12px')}; 
    color: ${(props) => props.theme.colors.greyScale.body};
    : hover {
        color: ${(props) => props.theme.colors.primary.regular};
        text-decoration: underline;
        cursor: pointer;
    }
`;
