export const roles = {
  finance: "Finance",
  developer: "Developer",
  viewer: "Viewer",
  admin: "Admin",
};

export const routeKeys = {
  myProfile: "myProfile",
  manageTeam: "manageTeam",
  addNewMember: "addNewMember",
  editMember: "editMember",
  duplicateMember: "duplicateMember",
};
