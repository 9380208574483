import React from "react";
import { AppDrawer } from "og-merchant-portal-react-library";
import { useFetchAppDrawerItems } from "./useFetchAppDrawerItems";
import { appIds } from "./constants";

export const AppDrawerMenuItem = ({title}) => {
  const { isLoading, appDrawerItems } = useFetchAppDrawerItems();
  return isLoading || appDrawerItems.length === 0 ? null : (
    <AppDrawer
      domIdToAppend={"app-drawer-root"}
      title={title}
      appDrawerItems={appDrawerItems}
      activeItemId={appIds.USER_MANAGER}
    />
  );
};
