import React, { useEffect, useState } from "react";
import { UserInfoContext } from "og-merchant-portal-react-library";
import httpClient, { httpClientUtils } from "../../utils/httpClient";

const UserProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {

    httpClient.get(
      `${httpClientUtils.getSetting('userInfoApiPath')}/users/details/`,
      (response) => {
        setUser({
          profile: response
        });
      },
      (error) => {
        console.log("Impossible to get the user. Check the falling http call.");
      }
    );
  }, []);

  if (user != null) {
    return (
        <UserInfoContext.Provider value={user}>
          {children}
        </UserInfoContext.Provider>
    );
  }

  return null;
};

export default UserProvider;
