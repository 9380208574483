import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Button } from 'og-merchant-portal-react-library';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const StyledErrorCode = styled.span`
  font-size: 142px;
  font-weight: ${props => props.theme.font.weights.medium};
  color: ${props => props.theme.colors.greyScale.body};
  line-height: normal;
  letter-spacing: -0.05em;
`;
const StyledErrorMessage = styled.span`
  margin-left: 7rem;
  max-width: 39.2rem;
  font-size: 52px;
  color: ${props => props.theme.colors.greyScale.body};
  line-height: normal;
  letter-spacing: -0.05em;
`;

const StyledRow = styled(Row)`
  height: 80vh;
  align-items: center;
`;

export const PageNotFoundCustom = ({ history }) => {
  const handleClick = e => {
    e.preventDefault();
    history.push('/');
  };
  return (
    <StyledRow justifyContent="center">
      <div className="error-page">
        <Row className="row" justifyContent="center">
          <StyledErrorCode className="error-page-code">404</StyledErrorCode>
          <StyledErrorMessage className="error-page-text">
            <FormattedMessage
              id="dashboard.pageNotFound.content"
              defaultMessage="We couldn't find this page"
            />
          </StyledErrorMessage>
        </Row>
        <Row justifyContent="center">
          <Button type="button" onClick={handleClick}>
            <FormattedMessage id="pageNotFound.url" defaultMessage="Back" />
          </Button>
        </Row>
      </div>
    </StyledRow>
  );
};

PageNotFoundCustom.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(PageNotFoundCustom);
