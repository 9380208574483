import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import { Drawer, TopMenuWithOverlayPanel } from 'og-merchant-portal-react-library';
import { KeycloackContext } from '../KeycloakProvider/KeycloakProvider';
import I18nContext from '../../components/IntlProvider/IntlContext';
import locales from './LanguageSelector/locale';
import { AppDrawerMenuItem } from './AppDrawerMenuItem/AppDrawerMenuItem';
import { useGetDataForNavigation } from './useGetDataForNavigation';
import { injectIntl } from 'react-intl';

const Navigation = (props) => {
  const { logoutUserFromKeycloak } = useContext(KeycloackContext);
  const { availableTranslations } = useContext(I18nContext);
  const availableLocalesAndTranslations = Object.keys(locales).length !== 0 && availableTranslations.length > 1;
  const { items } = useGetDataForNavigation(props);

  const handleOnHelpMenuItemClick = (targetItem) => {
    if (targetItem?.url) {
      window.open(targetItem.url);
    }
  };

  const ToggleDrawer = (isExpanded) => {
    props.setIsDrawerExpanded(isExpanded);
  };

  return (
    <React.Fragment>
      <TopMenuWithOverlayPanel
        items={items}
        logoUrl={`/themes/logo.svg`}
        rightLogoutLinkUrl={'/logout'}
        rightLogoutLinkLabel={props.intl.formatMessage({
          id: 'global.logout',
          defaultMessage: 'Logout',
        })}
        languageSelectorComponent={availableLocalesAndTranslations ? <LanguageSelector /> : null}
        appDrawerComponent={
          <AppDrawerMenuItem
            title={props.intl.formatMessage({
              id: 'global.menu',
              defaultMessage: 'Menu',
            })}
          />
        }
        onNavigationItemClick={(item) => {
          item.id
            ? item.id === 'logout'
              ? logoutUserFromKeycloak()
              : props.history.push(item.url)
            : logoutUserFromKeycloak();
        }}
        helpMenuItem={[]}
        onHelpMenuItemClick={handleOnHelpMenuItemClick}
      />
      <Drawer
        items={items}
        logoUrl={`/themes/logo.svg`}
        onExpandCollapse={(isExpanded) => {
          ToggleDrawer(isExpanded);
        }}
        onNavigationItemClick={(item) => {
          props.history.push(item.url);
        }}
      />
    </React.Fragment>
  );
};

export default injectIntl(withRouter(Navigation));
