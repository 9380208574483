import moment from 'moment';

export const httpClientUtils = {
  getSetting: (name) => {
    const { appSettings } = window;
    if (!appSettings) {
      throw new Error(
        "app settings are not loaded. Please check your index file"
      );
    }
    const setting = appSettings[name];
    if (!setting) {
      throw new Error(
        `${name} is not defined in your settings. Please check the appSettings file.`
      );
    }
    return setting;
  },
};

const httpClient = {
  get: (url, onSuccess, onError) => {
    global.sessionStorage.setItem("serverActivity", moment.utc().toISOString());
    const baseUrl = httpClientUtils.getSetting("apiDomain");
    fetch(baseUrl + url, {
      method: "GET",
      credentials: "include",
    })
      .then(onUnauthorized.redirectWhenNotAuthenticated)
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            onSuccess(json);
          });
        } else {
          if (onError) onError(response);
        }
      })
      .catch((error) => {
        if (onError) onError(error);
      });
  },

  post: (url, data, onSuccess, onError) => {
    global.sessionStorage.setItem("serverActivity", moment.utc().toISOString());
    const baseUrl =
      httpClientUtils.getSetting("apiDomain") +
      httpClientUtils.getSetting("myProfileApiPath");
    fetch(baseUrl + url, {
      method: "post",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(onUnauthorized.redirectWhenNotAuthenticated)
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            onSuccess(json);
          });
        } else {
          if (onError) onError(response);
        }
      })
      .catch((error) => {
        if (onError) onError(error);
      });
  },

  put: (url, data, onSuccess, onError) => {
    global.sessionStorage.setItem("serverActivity", moment.utc().toISOString());
    const baseUrl =
      httpClientUtils.getSetting("apiDomain") +
      httpClientUtils.getSetting("userInfoApiPath");
    fetch(baseUrl + url, {
      method: "put",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(onUnauthorized.redirectWhenNotAuthenticated)
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            // No Content
            onSuccess(null);
          } else {
            response.json().then((json) => {
              onSuccess(json);
            });
          }
        } else {
          if (onError) onError(response);
        }
      })
      .catch((error) => {
        if (onError) onError(error);
      });
  },
};

const onUnauthorized = {
  redirectWhenNotAuthenticated: (response) => {
    if (response.status === 401) {
      window.location.assign(loginUtils.getLoginPath());
    }
    return response;
  },
};

export const loginUtils = {
  getLoginPath: () => {
    const keycloakConfig = httpClientUtils.getSetting("keycloakConfig");
    const apiPath = httpClientUtils.getSetting("apiDomain");
    const userInfoPath = httpClientUtils.getSetting("userInfoApiPath");

    return `${apiPath}${userInfoPath}/auth/${keycloakConfig.realm}/login`;
  },
};

export default httpClient;
