import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const Control = ({ children, selectProps, ...props }) => {
  return (
    <components.Control {...props}>
      <div data-test={`${selectProps.name}_control`}>{children}</div>
    </components.Control>
  );
};
Control.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.object.isRequired
};

export default Control;