import React from "react";
import { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { TextV2 } from "og-merchant-portal-react-library";

import LogoutLogo from "./LogoutLogo";
import { loginUtils } from "../../../utils/httpClient";
import {
  StyledAnchor,
  StyledContent,
  StyledLogoutContainer,
  StyledTextContainer,
  StyledTitleContainer,
} from "./SessionTimeoutContent.styled";

function SessionTimeoutContent({ theme, intl }) {
  const timeoutTitle = intl.formatMessage({
    id: "dashboard.sessionTimeout.title",
    defaultMessage: "Automatically logged out",
  });

  return (
    <StyledContent>
      <StyledTitleContainer>
        <LogoutLogo />
        <TextV2 weight="semibold" size="h5" color={theme.colors.greyScale.body}>
          {timeoutTitle}
        </TextV2>
      </StyledTitleContainer>
      <StyledTextContainer>
        <TextV2 size="lg" data-test="global_sessionTimeout_message">
          <FormattedMessage
            id="dashboard.sessionTimeout.message"
            defaultMessage="For security reasons, you are logged out after {time} minutes of inactivity."
            values={{ time: 15 }}
          />
        </TextV2>
      </StyledTextContainer>
      <StyledLogoutContainer>
        <div id="dashboard.sessionTimeout.action">
          <StyledAnchor
            id="logout_button"
            href={loginUtils.getLoginPath()}
            role="button"
            data-testid="global_sessionTimeout_action_login"
          >
            <TextV2 color="secondary" size="subtitle">
              <FormattedMessage
                id="dashboard.sessionTimeout.login"
                defaultMessage="Login"
              />
            </TextV2>
          </StyledAnchor>
        </div>
      </StyledLogoutContainer>
    </StyledContent>
  );
}

SessionTimeoutContent.propTypes = {
  /** The theme object */
  theme: PropTypes.object.isRequired,

  /** `I18nProvider`'s object */
  intl: PropTypes.object.isRequired,
};

export default React.memo(injectIntl(withTheme(SessionTimeoutContent)));
