import { httpClientUtils } from "../../../utils/httpClient";

const fetchAuthenticationStatus = () => {
  const keycloakConfig = httpClientUtils.getSetting("keycloakConfig");

  const baseUrl = httpClientUtils.getSetting("apiDomain");

  const path = `${httpClientUtils.getSetting(
    "userInfoApiPath"
  )}/auth/${keycloakConfig.realm}/healthcheck`;

  return fetch(baseUrl + path, {
    method: "GET",
    credentials: "include",
  }).then((response) => response.status);
};

export default fetchAuthenticationStatus;
